import React from 'react'
import ContactCard from './ContactCard'
import FacilityCard from './FacilityCard'

const FacilityDetailsSidebar = ({ facility, facilities }) => {

    return (
        <>
            <div className={facility.description ? 'contact-sidebar' : 'contact-sidebar p-0'}>
                <div className={facility.description ? 'contact-sidebar-info' : 'contact-sidebar-info m-0'}>
                    <ul>

                        {facility.address &&
                            <>
                                <li>
                                    <div className="icon">
                                        <i className='bx bx-map'></i>
                                    </div>
                                    <span>Dirección</span>
                                    <p>
                                        {facility.address}
                                    </p>
                                </li>
                            </>
                        }
                        {facility.phone &&
                            <>
                                <li>
                                    <div className="icon">
                                        <i className='bx bxs-phone'></i>
                                    </div>
                                    <span>Teléfono</span>
                                    <p>
                                        {facility.phone}
                                    </p>
                                </li>

                            </>
                        }

                    </ul>
                </div>
            </div>
            <div className={facility.description ? 'contact-sidebar' : 'contact-sidebar mt-5 p-0'}>
                <div className={facility.description ? 'contact-sidebar-info' : 'contact-sidebar-info m-0'}>
                    <ul>
                        {facility.contacts.length ?
                            <li>
                                <div className="icon">
                                    <i className='bx bx-user-pin'></i>
                                </div>
                                <span>Relacionados:</span>
                                {facility.contacts.map((contact) => (
                                    <ContactCard
                                        key={`contact-${contact.id}`}
                                        contact={contact}
                                    />
                                ))}
                            </li>
                            : null
                        }



                        {facilities.length > 0 ?
                            <div className='widget widget_tracer_posts_thumb'>
                                <h3 className='widget-title'>Instalaciones</h3>
                                {facilities.map((facility) => (
                                    <FacilityCard
                                        key={`facility-${facility.id}`}
                                        facility={facility}
                                    />
                                ))}
                            </div>
                            :
                            null
                        }
                    </ul>
                </div>
            </div>
        </>


    )
}

export default FacilityDetailsSidebar