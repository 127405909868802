import React from 'react'
import { Link } from 'gatsby'

const FacilityCard = ({ facility }) => {
    const { media, name, slug, description } = facility

    return (
        <div className='row mb-3'>
            <div className='col-3'>
                {media[0] ?
                    <img src={media[0].original_url} alt={slug} />
                    :
                    null
                }
            </div>
            <div className='col-9'>
                <Link to={`/facility?slug=${slug}`}>
                    <h6>{name}</h6>
                    <small>{description}</small>
                </Link>
            </div>
        </div>
    )
}

export default FacilityCard