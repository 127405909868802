import React from 'react'
import { Link } from 'gatsby'

const ContactCard = ({ contact }) => {
    const { first_name, last_name, slug } = contact

    var name = last_name ? last_name + ', ' + first_name : first_name

    return (
        <Link to={`/contact?slug=${slug}`}>
            {name} <br />
        </Link>
    )
}

export default ContactCard