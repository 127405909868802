import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import FacilityDetailsContent from '../components/FacilityDetails/FacilityDetailsContent'
import { getFacility } from '../api/facility'
import { navigate } from 'gatsby'

const FacilityDetails = ({ location }) => {
    const [facility, setFacility] = useState(null)
    const [facilities, setFacilities] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                const data = await getFacility(slug)
                setFacility(data.facility)
                setFacilities(data.facilities)
            } catch (error) {
                navigate('/404')
            }
        })()
    }, [slug])

    if (!facility) return null

    return (
        <Layout title={facility.name}>
            <PageBanner  pageTitle='Instalaciones'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText='Instalaciones'/>
            <FacilityDetailsContent
                facility={facility}
                facilities={facilities}
            />
        </Layout>
    )
}

export default FacilityDetails